import { useEffect, useCallback, useState } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { API } from 'aws-amplify'
import URLParamHelper from '../../helpers/URLParamHelper'
import './DataHubPage.css'
import { usePlaidLink } from 'react-plaid-link'
import { LambdaClient, InvokeCommand } from '@aws-sdk/client-lambda'
import { Auth } from '@aws-amplify/auth'
import { Buffer } from 'buffer'
import { ReactComponent as CableImage } from '../../assets/il_connection.svg'
import { ReactComponent as WarningImage } from '../../assets/icons-generic-error.svg'
if (!window.Buffer) {
  window.Buffer = Buffer
}

const DataHubPage = () => {
  window.onbeforeunload = () => true // confirm before leaving page

  const nonce = URLParamHelper.get('nonce')
  const [public_token, setPublicToken] = useState('')
  const [meta_data, setMetaData] = useState({})
  const [opened, setOpened] = useState(false)
  const [inst_already_added, setInstAlreadyAdded] =
    useState<InstitutionAlreadyAdded>({
      id: '',
      user_id: '',
      client_connection_id: '',
      institution_id: '',
      item_id: '',
      link_token: '',
    })
  const [aborted, setAborted] = useState(false)
  const [not_selected_accounts, setNotSelectedAccounts] =
    useState<NotSelectedAccounts>([])

  const nonceQuery = useQuery({
    queryKey: ['conhub/nonce', nonce],
    queryFn: async () =>
      await API.get('userGateway', '/conhub/nonce', {
        queryStringParameters: {
          nonce: nonce,
        },
      }),
    enabled: !!nonce,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  })

  const addConnectionMutation = useMutation({
    mutationFn: async ({
      nonceData,
      public_token,
      meta_data,
      proceed_with_un_selection,
      aborted,
    }: AddAccountMutateArgs) => {
      const credentials = await Auth.currentCredentials()
      const client = new LambdaClient({
        region: 'eu-west-2',
        credentials: Auth.essentialCredentials(credentials),
      })
      const command = new InvokeCommand({
        FunctionName: 'processConnection-' + process.env.REACT_APP_ENV,
        Payload: JSON.stringify({
          body: {
            connection_id: nonceData.connectionId,
            user_id: nonceData.user_id,
            client_connection_id: nonceData.clientConnectionId,
            client_action_id: nonceData.clientActionId,
            redirect_uri: window.location.origin + '/datahub/',
            public_token: public_token,
            metadata: meta_data,
            proceed_with_un_selection: proceed_with_un_selection,
            aborted: aborted,
          },
        }),
        LogType: 'None',
      })
      const res = await client.send(command)
      const payload = res.Payload
        ? JSON.parse(Buffer.from(res.Payload).toString())
        : ''
      const body = payload ? JSON.parse(payload.body) : ''
      if (
        !res ||
        typeof res !== 'object' ||
        res.StatusCode !== 200 ||
        payload.statusCode !== 200 ||
        body.error
      ) {
        throw body.error ? body : 'error'
      }
      return body
    },
    onSuccess: (data: unknown, { nonceData }: AddAccountMutateArgs) => {
      window.onbeforeunload = null
      window.location.href = nonceData.successUrl
    },
    onError: (error: any, { nonceData }: AddAccountMutateArgs) => {
      if (error && typeof error === 'object' && error.hasOwnProperty('error')) {
        if (
          error.error === 'OVER_1_CONNECTION_AT_INSTITUTION_LIMIT' &&
          error.hasOwnProperty('other_connection')
        ) {
          const other_connection = error.other_connection
          setInstAlreadyAdded(other_connection)
          return
        } else if (error.error === 'ABORTED') {
          window.onbeforeunload = null
          window.location.href = nonceData.abortUrl
          return
        } else if (error.error === 'ACCOUNTS_NOT_SELECTED') {
          const not_selected_accounts = error.not_selected
          setNotSelectedAccounts(not_selected_accounts)
          return
        }
      }
      console.error('Error adding account', error)
      window.onbeforeunload = null
      window.location.href = nonceData.errorUrl
    },
  })
  //Plaid Link
  const onSuccess = useCallback(
    (public_token: PublicToken, metadata: any) => {
      const consent_timestamp = new Date().toISOString()
      setMetaData({ ...metadata, ...{ consent_timestamp: consent_timestamp } })
      setPublicToken(public_token)
      addConnectionMutation.mutate({
        nonceData: nonceQuery.data,
        public_token: public_token,
        meta_data: { ...metadata, ...{ consent_timestamp: consent_timestamp } },
      })
    },
    [nonceQuery, addConnectionMutation]
  )

  const onExit = useCallback(
    (error: any, metadata: any) => {
      setAborted(true)
      addConnectionMutation.mutate({
        nonceData: nonceQuery.data,
        public_token: public_token,
        meta_data: {
          ...metadata,
          ...{ consent_timestamp: null, callback_error: JSON.stringify(error) },
        },
        aborted: true,
      })
    },
    [nonceQuery]
  )

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: nonceQuery.data ? nonceQuery.data.linkToken : false,
    onSuccess,
    onExit,
  }
  const { open, ready } = usePlaidLink(config)

  // open Link immediately when ready
  useEffect(() => {
    if (
      nonceQuery.isSuccess &&
      nonceQuery.data &&
      addConnectionMutation.isIdle &&
      ready &&
      !opened
    ) {
      open()
      setOpened(true)
    }
  }, [nonceQuery, addConnectionMutation, ready, open, opened])

  const pleaseReturnBlaBla =
    'Please return to the Connection Hub and try again.'

  const heading =
    !nonce || nonceQuery.isError
      ? 'Error'
      : nonceQuery.isLoading
      ? 'Initialising'
      : addConnectionMutation.isIdle ||
        (aborted && !addConnectionMutation.isError)
      ? "We're connecting you to Plaid"
      : inst_already_added.id
      ? 'You have added this bank already'
      : not_selected_accounts.length > 0
      ? "You haven't selected some of your currently connected accounts from this bank"
      : addConnectionMutation.isSuccess || addConnectionMutation.isError
      ? "We're taking you back to YouGov"
      : "We're connecting your accounts"

  const subheading = !nonce ? (
    'Your url is invalid. ' + pleaseReturnBlaBla
  ) : nonceQuery.isLoading ? (
    ''
  ) : nonceQuery.isError ? (
    'This link is no longer valid. ' + pleaseReturnBlaBla
  ) : inst_already_added.id ? (
    <text>
      To add more accounts from this bank you'll need to{' '}
      <text style={{ fontWeight: 'bold' }}>
        delete your existing connection
      </text>{' '}
      to this bank first.
      <br />
      <br />
      You can do this via the 3 dots in the 'My banks' section.
    </text>
  ) : not_selected_accounts.length > 0 ? (
    <text>
      Only the accounts you have selected will be renewed. Any unselected
      accounts will now stop sharing data.
      <br />
      <br />
      You can remove all the data we hold about this bank by deleting it via the
      3 dots in the 'My banks' section.
    </text>
  ) : (
    'Please do not close or refresh this page. It can take a few minutes'
  )

  const loaderMessage =
    inst_already_added.id || not_selected_accounts.length > 0
      ? ''
      : addConnectionMutation.isPending && !aborted
      ? 'Retrieving data from bank'
      : addConnectionMutation.isSuccess || addConnectionMutation.isError
      ? 'Redirecting'
      : ''

  const showLoader = !(
    !nonce ||
    nonceQuery.isError ||
    inst_already_added.id ||
    not_selected_accounts.length > 0
  )

  const loaderProgress =
    addConnectionMutation.isSuccess || addConnectionMutation.isError
      ? 100
      : addConnectionMutation.isPending && !aborted
      ? 60
      : nonceQuery.isSuccess
      ? 20
      : nonceQuery.isLoading
      ? 5
      : 0

  return (
    <div id='datahub-page-page-container'>
      <div id='datahub-page-main-container'>
        {showLoader && <CableImage id='datahub-page-cable-img' />}
        {not_selected_accounts.length > 0 && (
          <WarningImage id='datahub-page-cable-img' />
        )}
        <h2 id='datahub-page-heading'>{heading}</h2>
        {subheading && <div id='datahub-page-sub-heading'>{subheading}</div>}
        {(inst_already_added.id ||
          not_selected_accounts.length > 0 ||
          !nonce ||
          nonceQuery.isError) && (
          <div className='datahub-page-button-container'>
            <button
              className={
                not_selected_accounts.length > 0
                  ? 'datahub-page-button-back-not-selected'
                  : 'datahub-page-button'
              }
              onFocus={event => {
                event.target.style['outline'] = '4px solid #92dcff'
              }}
              onClick={() => {
                if (not_selected_accounts.length > 0) {
                  setNotSelectedAccounts([])
                  addConnectionMutation.mutate({
                    nonceData: nonceQuery.data,
                    public_token: public_token,
                    meta_data: meta_data,
                    aborted: true,
                  })
                } else {
                  if (!nonce || nonceQuery.isError) {
                    if (process.env.REACT_APP_ENV === 'prod') {
                      window.onbeforeunload = null
                      window.location.href =
                        'https://account.yougov.com/gb-en/account'
                    } else {
                      window.onbeforeunload = () => false
                      window.location.href =
                        'https://panel-staging--panel.istio-emea.yougov.net/gb-en/account'
                    }
                  } else {
                    window.onbeforeunload = null
                    window.location.href = nonceQuery.data.errorUrl
                  }
                }
              }}>
              Back
            </button>
            {not_selected_accounts.length > 0 && (
              <button
                className={'datahub-page-button'}
                onFocus={event => {
                  event.target.style['outline'] = '4px solid #92dcff'
                }}
                onClick={() => {
                  setNotSelectedAccounts([])
                  addConnectionMutation.mutate({
                    nonceData: nonceQuery.data,
                    public_token: public_token,
                    meta_data: meta_data,
                    proceed_with_un_selection: true,
                  })
                }}>
                Proceed
              </button>
            )}
          </div>
        )}
        {showLoader && (
          <>
            <div id='datahub-page-loader-container'>
              <div
                id='datahub-page-loader-bar'
                style={{ width: `${loaderProgress}%` }}
              />
            </div>
            {loaderMessage && (
              <div id='datahub-page-loader-message'>{loaderMessage}</div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default DataHubPage

type NonceData = {
  connectionId: string
  user_id: string
  clientConnectionId: string
  clientActionId: string
  errorUrl: string
  successUrl: string
  abortUrl: string
}

type InstitutionAlreadyAdded = {
  id: string
  user_id: string
  client_connection_id: string
  institution_id: string
  item_id: string
  link_token: string
}

type NotSelectedAccounts = [name: string] | []

type PublicToken = string

type AddAccountMutateArgs = {
  nonceData: NonceData
  public_token: string
  meta_data: object
  proceed_with_un_selection?: boolean | null
  aborted?: boolean
}
